<template>
  <lazy-load @loaded="visible = true">
    <div class="flex justify-between items-center mb-3">
      <div class="font-niceone-medium text-lg">{{ data.name }}</div>
      <nuxt-link v-if="data.seo_url" :to="formattedSeoUrl(data)"
                 class="text-sm underline">
        {{ $t('common.view_all') }}
      </nuxt-link>
    </div>
    <template v-if="isLoading || !visible">
      <product-slider-skeleton/>
    </template>
    <template v-else>
      <template v-if="products.length">
        <product-slider :data="data" :products="products"
                        :source-data="setEventsData(data)"/>
      </template>
    </template>
  </lazy-load>
</template>

<script setup>
import LazyLoad from "@/components/LazyLoad"
import productSlider from "@/components/home/product-slider/index";
import productSliderSkeleton from "@/components/skeleton/ProductSlider";
import {getDesktopProductList} from "@/plugins/API/home"
import {EVENT_SOURCES} from "@/constants/events";
import {formattedSeoUrl} from "@/utilities";

const props = defineProps({
  data: {
    type: Object,
    default: () => {
    }
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const visible = ref(false)
const {componentId, targetId, sort} = props.data

const {isLoading, data: response} = getDesktopProductList({componentId, targetId, sort}, {
  enabled: visible
})
const products = computed(() => {
  return response.value?.products || []
})


const setEventsData = (data) => {
  return {...EVENT_SOURCES.HOME_SECTION, source_name: data?.en_name, source_id: data?.componentId, ISIHOME: true}
}
</script>